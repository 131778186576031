import { Box, Circle, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Number4Icon } from '@/components/Icons';
import { NextImage } from '@/components/Image';
import Seo from '@/components/Seo';

import breakpoints from '@/../themes/default-theme/breakpoints';
import { AppService } from '@/services';

function NotFoundPage() {
    const intl = useIntl();
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });

    const [desktop, setDesktop]: [
        boolean | undefined,
        Dispatch<SetStateAction<boolean | undefined>>
    ] = useState();

    useEffect(() => {
        setDesktop(isDesktop);
    }, [isDesktop]);

    return (
        <>
            <Seo
                templateTitle={intl.formatMessage({ id: 'page-not-found' })}
                hideBreadCrumbs={true}
            />

            <Box
                as="section"
                display="flex"
                flexDirection="column"
                minH="60vh"
                textAlign="center"
                overflow="hidden"
                alignItems="center"
                justifyContent="center"
                py={20}
                px={4}
            >
                <Flex
                    h={desktop ? '12.5rem' : '6.25rem'}
                    alignItems="stretch"
                    gap={6}
                    pointerEvents="none"
                >
                    <Number4Icon fill="brand.main" h="full" w="auto" />
                    <Circle size={desktop ? 200 : 100} overflow="hidden">
                        <NextImage
                            src={AppService.getPathWithCountryCode('/images/creme.webp')}
                            alt="0"
                            width={desktop ? 200 : 100}
                            height={desktop ? 200 : 100}
                            style={{
                                objectFit: 'contain',
                                transition: 'none'
                            }}
                        />
                    </Circle>
                    <Number4Icon fill="brand.main" h="full" w="auto" />
                </Flex>

                <Flex flexDirection="column" mt={20} rowGap={10}>
                    <Flex flexDirection="column" rowGap={3}>
                        <Text fontSize="h3" fontWeight="medium">
                            <FormattedMessage id="page.404.title" />
                        </Text>
                        <Text fontSize="lg" fontWeight="normal" color="grey.main">
                            <FormattedMessage id="page.404.description-1" />
                        </Text>
                    </Flex>

                    <Flex flexDirection="column" rowGap={6}>
                        <Text fontSize="normal" fontWeight="medium">
                            <FormattedMessage id="page.404.description-2" />
                        </Text>
                        <Flex flexDirection="column" rowGap={2}>
                            <Text fontSize="normal">
                                <FormattedMessage id="page.404.description-3" />
                            </Text>
                            <Text fontSize="normal">
                                <FormattedMessage id="page.404.description-4" />
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        </>
    );
}

export default NotFoundPage;
